<template>
  <div class="partnermerchant" id="partnermerchant">
    <div class="ui grid container faq">
      <div class="intro-content">
        <p class="path">Frequently Asked Questions > Partner or Merchant</p>
        <h3>Partner <span>or</span> Merchant</h3>
        <h4 id="pm1">What is a TayoCash Partner or Merchant?</h4>
        <div class="paragraph">
          <p>
            A <span class="vtxt-align">TayoCash Partner</span> is an individual or entity that is accredited by TayoCash to offer and serve as a contact point for Cash In, Cash Out, Buy Load, and/or Pay Bills. A TayoCash Partner earns an agreed percentage for each successful transaction.
          </p>
          <p>
            A <span class="vtxt-align">TayoCash Merchant</span> is an individual or entity that is accredited by TayoCash to offer and serve as a contact point, not only for Cash In, Cash Out, Buy Load, and/or Pay Bills, but also for Remittance — earning an agreed percentage for each successful transaction. Subject to an agreed fee, a TayoCash Merchant can also use the TayoCash Pay QR facility as a cashless and contactless payment option for their customers.
          </p>
        </div>

        <h4 id="pm2">What benefits do I get when I become a TayoCash Partner or Merchant?</h4>
        <div class="paragraph">
          <p>
            TayoCash Partners and Merchants can earn <span class="vtxt-align">extra income</span> since they can earn a percentage of the fees for each transaction when they provide TayoCash services in their own time and convenience. They can also avail of upcoming <span class="vtxt-align">discounts and promos</span> exclusive to TayoCash Partners and Merchants.
          </p>
          <p>
            TayoCash Merchants can have their <span class="vtxt-align">own QR codes</span>, which can be scanned by their customers as another contactless payment option for their businesses. Their businesses can also be <span class="vtxt-align">promoted to TayoCash Members</span> through the TayoCash app, email, SMS, and social media.
          </p>
        </div>

        <h4 id="pm3">How do I become a TayoCash Partner or Merchant?</h4>
        <div class="paragraph">
          <p>
            You must first be a <span class="vtxt-align">Fully Verified Member</span> before you can apply as a TayoCash Partner or Merchant.
          </p>
          <p><i>
            Not yet a TayoCash Fully Verified Member? Click <a class="link" href="/faq/verification">here</a> to know how to become a Fully Verified Member.
          </i></p>
          <p>
            1. Once you are a Fully Verified Member, download and complete the <a class="link" href="https://assets.prod.tayocash.com/forms/partner_merchant_enrollment_form.pdf" download target="_blank">TayoCash Partner & Merchant Enrollment Form</a> and submit via email to <a class="link" href="mailto:business@tayocash.com">business@tayocash.com</a>.
          </p>
          <p>
            2. TayoCash will review your application and notify you once approved.
          </p>
          <p>
            For other inquiries about becoming a TayoCash Partner or Merchant, you may contact us at <a class="link" href="mailto:business@tayocash.com">business@tayocash.com</a>.
          </p>
          <p>
            TayoCash Partners and Merchants are subject to TayoCash’s <a class="link" href="/termsandconditions">Terms and Conditions</a>.
          </p>
        </div>

        <h4 id="pm4">I am currently a TayoCash Partner. Can I become a TayoCash Merchant?</h4>
        <div class="paragraph">
          <p>
            If you are already a TayoCash Partner, you can apply to become a TayoCash Merchant and enjoy the additional benefits available to TayoCash Merchants.
          </p>
          <p>
            1. Accomplish the <a class="link" href="https://assets.prod.tayocash.com/forms/partner_merchant_change_request_form.pdf" download target="_blank">Change Request Form</a> that can be found in the <a class="link" href="/helpcenter">Help Center</a> and submit via email to <a class="link" href="mailto:business@tayocash.com">business@tayocash.com</a>.
          </p>
          <p>
            2. TayoCash will review the information and the documents you submitted. You will receive an email and notification on your app once your application is approved.
          </p>
        </div>

        <div class="buttons">
          <a href="/faq/verification" class="yellow">BACK</a>
          <a href="/faq/services" class="white">NEXT</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'partnermerchant'
}
</script>

<style scoped lang='scss'>
</style>
